/* ---------------------------
Testimonial Styles 
------------------------------*/


.react-tabs__tab-panel{
    &.react-tabs__tab-panel--selected {
        .rn-testimonial-content{
            opacity: 1;
            -webkit-filter: blur(0);
            filter: blur(0);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
}
.rn-testimonial-content {
    -webkit-filter: blur(30px);
    filter: blur(30px);
    opacity: 0;
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: opacity .4s cubic-bezier(0.82,0,0.12,1) .2s,-webkit-filter .3s cubic-bezier(0.82,0,0.12,1) .1s,-webkit-transform .6s cubic-bezier(0.82,0,0.12,1);
    transition: opacity .4s cubic-bezier(0.82,0,0.12,1) .2s,-webkit-filter .3s cubic-bezier(0.82,0,0.12,1) .1s,-webkit-transform .6s cubic-bezier(0.82,0,0.12,1);
    transition: filter .3s cubic-bezier(0.82,0,0.12,1) .1s,transform .6s cubic-bezier(0.82,0,0.12,1),opacity .4s cubic-bezier(0.82,0,0.12,1) .2s;
    transition: filter .3s cubic-bezier(0.82,0,0.12,1) .1s,transform .6s cubic-bezier(0.82,0,0.12,1),opacity .4s cubic-bezier(0.82,0,0.12,1) .2s,-webkit-filter .3s cubic-bezier(0.82,0,0.12,1) .1s,-webkit-transform .6s cubic-bezier(0.82,0,0.12,1);
    .inner {
        padding-bottom: 62px;
        @media #{$sm-layout} {
            padding-bottom: 20px;
        }
        @media #{$md-layout} {
            padding-bottom: 30px;
        }
        p {
            font-size: 36px;
            line-height: 58px;
            color: #18181b;
            font-weight: 500;
            padding: 0 9%;
            margin-bottom: 0;
            margin-top: -16px;
            position: relative;
            z-index: 2;
            @media #{$lg-layout} {
                font-size: 32px;
                line-height: 53px;
            } 
            @media #{$md-layout} {
                font-size: 28px;
                line-height: 48px;
                padding: 0;
            } 
            @media #{$sm-layout} {
                font-size: 17px !important;
                line-height: 30px !important;
                padding: 0;
                font-weight: 400;
            } 

            &::before{
                position: absolute;
                width: 197px;
                height: 100%;
                left: 25%;
                top: -50px;
                background-image: url(/assets/images/client/image-1.png);
                content: "";
                opacity: 1;
                background-repeat: no-repeat;
                z-index: -1;
                @media #{$sm-layout} {
                    top: -15px;
                    background-size: 100px;
                    left: 50%;
                    right: 0;
                    width: 100%;
                    margin-left: -48px;
                } 
            }
            &::after{
                position: absolute;
                width: 197px;
                height: 202px;
                right: 25%;
                bottom: -115px;
                background-image: url(/assets/images/client/image-2.png);
                content: "";
                opacity: 1;
                background-repeat: no-repeat;
                z-index: -1;
                @media #{$sm-layout} {
                    display: none;
                } 
            }
        }
    }
    .author-info{
        margin-bottom: 50px;
        @media #{$sm-layout} {
            margin-bottom: 30px;
        }
        @media #{$md-layout} {
            margin-bottom: 30px;
        }
        h6 {
            font-size: 18px;
            line-height: 50px;
            color: #8c8c8c;
            font-weight: 300;
            @media #{$sm-layout} {
                line-height: 20px;
                font-size: 14px;
            }
            span{
                color: #18181b;
                text-transform: uppercase;
            }
        }
    }
}


.rn-testimonial-light {
    .rn-testimonial-content {
        .inner {
            p {
                color: #c6c9d8;
            }
        }
        .author-info {
            h6 {
                color: #ffffff;
                span {
                    color: #c6c9d8;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.rn-paralax-testimonial{
    .rn-testimonial-content{
        .inner{
            p{
                color: #ffffff;
                &::before,
                &::after{
                    opacity: 0.1;
                }
            }
        }
    }

}


/* ------------------------------
Testimonial Thumbnail 
-------------------------------- */

ul {
    &.testimonial-thumb-wrapper {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 510px;
        margin: 0px auto;
        margin-bottom: -30px;
        outline: none;
        @media #{$large-mobile} {
            margin-bottom: -10px;
        }
        li {
            padding-left: 15px;
            padding-right: 15px;
            flex-basis: 20%;
            margin-bottom: 30px;
            outline: none;
            cursor: pointer;

            @media #{$sm-layout} {
                flex-basis: 20%;
                margin-bottom: 14px;
                padding-left: 7px;
                padding-right: 7px;
            }

            @media #{$large-mobile} {
                flex-basis: 25%;
                padding-left: 5px;
                padding-right: 5px;
                margin-bottom: 10px;
            }

            .thumb {
                position: relative;
                display: inline-block;
                width: 50%;
                border-radius: 60px;
                border-color: #18181b;
                border-width: 5px;
                p {
                    @extend %transition;
                    border-radius: 60px;
                    border-color: #18181b;
                    width: 40%;
                    &:hover {
                        transform: scale(1.1);
                        object-fit: contain;
                        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                    }
                }
                &::after {
                    width: 13px;
                    height: 13px;
                    background: $theme-color;
                    right: -8.5px;
                    top: -8.5px;
                    position: absolute;
                    content: "";
                    border-radius: 100%;
                    @extend %transition;
                    opacity: 0;
                    transform: scale(0);
                }
            }
            &.react-tabs__tab--selected {
                .thumb {
                    &::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                    img {
                        transform: scale(1.1);
                        object-fit: contain;
                        border-radius: 6px;
                        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
}

.col-center {
	margin: 0 auto;
	float: none !important;
}
.carousel {
	padding: 0 70px;
}
.carousel .carousel-item {
	color: #999;
	font-size: 14px;
	text-align: center;
	overflow: hidden;
	min-height: 290px;
}
.carousel .carousel-item .img-box {
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 30px 0 10px;
}
.carousel .overview {	
	font-style: italic;
}
.carousel .overview b {
	text-transform: uppercase;
	color: #18181b;
}
.carousel-control-prev, .carousel-control-next {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	top: 50%;
	background: none;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 68px;
	line-height: 42px;
	position: absolute;
	display: inline-block;
	color: rgba(0, 0, 0, 0.8);
	text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 12px;
	height: 12px;
	margin: 1px 3px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}

.faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    font-size: 18px;
    text-shadow: #18181b;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
  